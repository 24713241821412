import React, { useState } from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../../config";

const Edit = ({ employees, selectedEmployee, setEmployees, setIsEditing }) => {
  const id = selectedEmployee.id;

  const [username, setUsername] = useState(selectedEmployee.username);
  const [hosts, setHosts] = useState(selectedEmployee.hosts);
  const [hardwareId, setHWID] = useState(selectedEmployee.hardwareId);
  const [expiration, setExpiration] = useState(selectedEmployee.expirationDate);

  const handleUpdate = (e) => {
    e.preventDefault();

    const employee = {
      username,
      hosts,
      hardwareId,
      expirationDate:expiration,
    };

    const token = localStorage.getItem("token");

    fetch(`${BASE_URL}/account/user/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(employee),
    })
      .then((response) => response.json())
      .then((data) => {
        setEmployees(
          employees.map((emp) => (emp.id === employee.id ? data : emp))
        );
        setIsEditing(false);

        Swal.fire({
          icon: "success",
          title: "Updated!",
          text: `${employee.username}'s data has been updated.`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="small-container">
      <form onSubmit={handleUpdate}>
        <h1>Edit User</h1>
        <label htmlFor="firstName">Username</label>
        <input
          id="firstName"
          type="text"
          name="firstName"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="lastName">Hosts</label>
        <input
          id="lastName"
          type="text"
          name="lastName"
          value={hosts}
          onChange={(e) => setHosts(+e.target.value)}
        />
        <label htmlFor="email">Hardware Id</label>
        <input
          id="email"
          type="text"
          name="email"
          value={hardwareId}
          onChange={(e) => setHWID(e.target.value)}
        />
        <label htmlFor="date">Expiration Date</label>
        <input
          id="date"
          type="text"
          name="date"
          value={expiration}
          onChange={(e) => setExpiration(e.target.value)}
        />
        <div style={{ marginTop: "30px" }}>
          <input type="submit" value="Update" />
          <input
            style={{ marginLeft: "12px" }}
            className="muted-button"
            type="button"
            value="Cancel"
            onClick={() => setIsEditing(false)}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
