import React, { useState } from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../../config";

const Add = ({ employees, setEmployees, setIsAdding }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hosts, setHosts] = useState("");

  const handleAdd = (e) => {
    e.preventDefault();

    if (!username || !password || !hosts) {
      return Swal.fire({
        icon: "error",
        title: "Error!",
        text: "All fields are required.",
        showConfirmButton: true,
      });
    }

    const newEmployee = {
      username,
      password,
      hosts,
    };

    const token = localStorage.getItem("token"); // Retrieve token from local storage

    fetch(`${BASE_URL}/account/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newEmployee),
    })
      .then((response) => response.json())
      .then((data) => {
        setEmployees(employees);
        setIsAdding(false);

        Swal.fire({
          icon: "success",
          title: "Added!",
          text: `${username}'s data has been Added.`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="small-container">
      <form onSubmit={handleAdd}>
        <h1>Add User</h1>
        <label htmlFor="firstName">Username</label>
        <input
          id="firstName"
          type="text"
          name="firstName"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="lastName">Password</label>
        <input
          id="lastName"
          type="text"
          name="lastName"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label htmlFor="email">Hosts</label>
        <input
          id="email"
          type="text"
          name="email"
          value={hosts}
          onChange={(e) => setHosts(+e.target.value)}
        />
        <div style={{ marginTop: "30px" }}>
          <input type="submit" value="Add" />
          <input
            style={{ marginLeft: "12px" }}
            className="muted-button"
            type="button"
            value="Cancel"
            onClick={() => setIsAdding(false)}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
